import React, { useEffect, useState } from 'react';
import {
  Routes,
  Navigate,
  Route,
  Link as Navigationlink,
} from "react-router-dom";
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const ImportButton = styled(Button)`
  background-color: green;
`;







const apiUrl = "https://us-central1-productdatamanagement.cloudfunctions.net/importPdMagento-2"

export default (props) => {
  const [inputValue, setInputValue] = useState('');
  const [responseData, setResponseData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('magento & brandingdb');
  const [taskID, setTaskID] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null); // New state for task status

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = async () => {
    setIsLoading(true);
  
      // Replace this URL with the actual API endpoint you want to request
      //const apiUrl = 'http://192.168.33.147:8080';
      const magento = selectedOption.includes('magento');
      const brandingdb = selectedOption.includes('brandingdb');
  
      const requestBody = JSON.stringify({
        foldername: inputValue.replace(/\.zip$/, ''),
        importtype: { magento, brandingdb },
      });
  
  
      // Make an HTTPS POST request to the API with the input value in the body
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json',
          },
          body: requestBody,
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        setTaskID(data.task_id);
        setResponseData(`API Response: ${JSON.stringify(data)}`);
      } catch (error) {
        console.error('Error:', error);
        setResponseData('Error occurred while fetching data.');
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      let timer;
      if (taskID) {
        const checkTaskStatus = async () => {
          //const apiUrl = 'http://192.168.33.147:8080';
          const requestBody = JSON.stringify({
            path: 'check',
            task_id: taskID,
          });
    
          try {
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Accept: 'application/json',
              },
              body: requestBody,
            });
    
            const data = await response.json();
            console.log(data)
            setTaskStatus(data.status);
    
            if (data.status === 'finished') {
              clearInterval(timer);
            }
          } catch (error) {
            // Handle errors if any
            console.error('Error checking task status:', error);
          }
        };
    
        // Use a timer to periodically check the task status
        timer = setInterval(checkTaskStatus, 10000); // Adjust the interval as needed
      }
  
      // Cleanup the interval on component unmount or when taskID changes
      return () => clearInterval(timer);
  
    }, [taskID]);

  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
        style={{ maxWidth: '60%', padding: '30px 30px 0px' }}
      >
        ImportManager
      </Typography>

      <Grid
        container
        spacing={2}
        justifyContent="center" // Center-align the items horizontally
        alignItems="center" // Center-align the items vertically
        style={{ maxWidth: '60%', padding: '30px' }} // Remove the maximum width
      >
      <Grid item xs={12}>
        <FormControl fullWidth  >
            <Select 
              label="Select Importtype"
              value={selectedOption}
              onChange={handleOptionChange}
              className="base-OptionGroup-label"
            >
              <MenuItem value="magento & brandingdb">Import magento and brandingdb</MenuItem>
              <MenuItem value="magento">magento import</MenuItem>
              <MenuItem value="brandingdb">brandingdb import</MenuItem>
            </Select>
        </FormControl>
      </Grid>
        <Grid item xs={12}>
          <TextField
            label="Input Value"
            variant="outlined"
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} >
          <ImportButton
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={isLoading}
            fullWidth
          >
            {isLoading ? 'Loading...' : 'Start Import'}
          </ImportButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" style={{ marginTop: '16px' }}>
            {taskID && (
              <div>
                <p>Task ID: {taskID}</p>
                <p>Task Status: {taskStatus}</p>
              </div>
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
